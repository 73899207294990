import * as React from 'react';
import { useState } from 'react';

// @ts-ignore
import closeIcon from './close.svg';

const JobAlerts: React.FC = () => {
	const [popupOpened, setPopupOpened] = useState(false);
	return (
		<section>
			<div
				className={
					'w-full flex flex-row justify-center flex-wrap gap-3 items-center bg-primary px-5 pt-8 text-center text-white'
				}
			>
				<h2 className={'font-lato text-2xl mb-0 font-bold text-white'}>
					Sign up for Job Alerts
				</h2>
				<button
					className={
						'rounded-full bg-secondary p-2 px-10 font-lato text-xl font-bold text-white hover:text-white hover:opacity-90 focus:opacity-90'
					}
					onClick={() => {
						setPopupOpened(true);
					}}
				>
					Join
				</button>
			</div>
			<div
				className={[
					'fixed top-0 bottom-0 right-0 z-10 left-0 bg-[rgba(0,0,0,0.40)]',
					!popupOpened ? 'hidden' : ''
				].join(' ')}
			>
				<div
					className={
						'flex h-full w-full flex-row items-center justify-center'
					}
				>
					<div
						className={'container flex items-center justify-center'}
					>
						<div
							className={
								'rounded bg-white px-12 shadow max-sm:px-5'
							}
						>
							<div className={'flex flex-row justify-between'}>
								<span
									className={
										' py-8 font-lato text-center text-2xl font-bold text-gp-green'
									}
								>
									JOIN THE TALENT COMMUNITY
								</span>
								<button
									onClick={() => {
										setPopupOpened(false);
									}}
								>
									<img
										width={26}
										className={'hover:opacity-70'}
										src={closeIcon}
										alt={'close popup window'}
									/>
								</button>
							</div>
							<iframe
								className={
									'max-sm:h-[45 0px] h-[500px] max-h-full w-[500px]	max-w-full'
								}
								style={{ border: 'none', maxHeight: '100%' }}
								src={
									'https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=40ddfd9f-dd1c-4751-a9b4-e8749220ca4a&ccId=19000101_000001&lang=en_US&selectedMenuKey=TalentCommunity'
								}
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default JobAlerts;
